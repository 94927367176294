export const setSelected = (selected) =>
	({
		type    : 'SET_SELECTED',
		payload : { selected }
	});

export const setMe = ({ peerId, loginEnabled }) =>
	({
		type    : 'SET_ME',
		payload : { peerId, loginEnabled }
	});

export const setBrowser = (browser) =>
	({
		type    : 'SET_BROWSER',
		payload : { browser }
	});

export const loggedIn = (flag) =>
	({
		type    : 'LOGGED_IN',
		payload : { flag }
	});

export const loggedInWithPin = (flag) =>
	({
		type    : 'LOGGED_IN_WITH_PIN',
		payload : { flag }
	});

export const isRecorder = (flag) =>
	({
		type    : 'IS_RECORDER',
		payload : { flag }
	});

export const addRole = (roleId) =>
	({
		type    : 'ADD_ROLE',
		payload : { roleId }
	});

export const removeRole = (roleId) =>
	({
		type    : 'REMOVE_ROLE',
		payload : { roleId }
	});

export const setPicture = (picture) =>
	({
		type    : 'SET_PICTURE',
		payload : { picture }
	});

export const setMediaCapabilities = ({
	canSendMic,
	canSendWebcam,
	canShareScreen,
	canShareFiles
}) =>
	({
		type    : 'SET_MEDIA_CAPABILITIES',
		payload : { canSendMic, canSendWebcam, canShareScreen, canShareFiles }
	});

export const setAudioDevices = (devices) =>
	({
		type    : 'SET_AUDIO_DEVICES',
		payload : { devices }
	});

export const setAudioOutputDevices = (devices) =>
	({
		type    : 'SET_AUDIO_OUTPUT_DEVICES',
		payload : { devices }
	});

export const setWebcamDevices = (devices) =>
	({
		type    : 'SET_WEBCAM_DEVICES',
		payload : { devices }
	});

export const setRaisedHand = (flag) =>
	({
		type    : 'SET_RAISED_HAND',
		payload : { flag }
	});

export const setBMDChangeInProgress = (flag) =>
	({
		type    : 'SET_BMD_CHANGE_IN_PROGRESS',
		payload : { flag }
	});

export const setRouterState = (dest, id) =>
	({
		type    : 'SET_BMD_ROUTER_STATE',
		payload : { dest, id }
	});

export const setRouterConfig = (routerConfig) =>
	({
		type    : 'SET_BMD_ROUTER_CONFIG',
		payload : { routerConfig }
	});

export const setCurrentVolume = (currentVolume) =>
	({
		type    : 'SET_CURRENT_VOLUME',
		payload : { currentVolume }
	});

export const setShutdownEnabled = (shutdownEnabled) =>
	({
		type    : 'SET_SHUTDOWN_ENABLED',
		payload : { shutdownEnabled }
	});

export const setAddressBook = (addressBook) =>
	({
		type    : 'SET_ADDRESS_BOOK',
		payload : { addressBook }
	});

export const setAudioInProgress = (flag) =>
	({
		type    : 'SET_AUDIO_IN_PROGRESS',
		payload : { flag }
	});

export const setAudioOutputInProgress = (flag) =>
	({
		type    : 'SET_AUDIO_OUTPUT_IN_PROGRESS',
		payload : { flag }
	});

export const setWebcamInProgress = (flag) =>
	({
		type    : 'SET_WEBCAM_IN_PROGRESS',
		payload : { flag }
	});

export const setScreenShareInProgress = (flag) =>
	({
		type    : 'SET_SCREEN_SHARE_IN_PROGRESS',
		payload : { flag }
	});

export const setRaisedHandInProgress = (flag) =>
	({
		type    : 'SET_RAISED_HAND_IN_PROGRESS',
		payload : { flag }
	});

export const setJoinInProgress = (flag) =>
	({
		type    : 'SET_JOIN_IN_PROGRESS',
		payload : { flag }
	});

export const setDisplayNameInProgress = (flag) =>
	({
		type    : 'SET_DISPLAY_NAME_IN_PROGRESS',
		payload : { flag }
	});

export const setIsSpeaking = (flag) =>
	({
		type    : 'SET_IS_SPEAKING',
		payload : { flag }
	});

export const setAutoMuted = (flag) =>
	({
		type    : 'SET_AUTO_MUTED',
		payload : { flag }
	});
