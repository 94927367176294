const initialState =
{
	id                    : null,
	picture               : null,
	browser               : null,
	roles                 : [],
	canSendMic            : false,
	canSendWebcam         : false,
	canShareScreen        : false,
	canShareFiles         : false,
	audioDevices          : null,
	webcamDevices         : null,
	webcamInProgress      : false,
	audioInProgress       : false,
	screenShareInProgress : false,
	displayNameInProgress : false,
	BMDChangeInProgress   : false,
	routerState           : [],
	routerConfig          : null,
	addressBook           : null,
	currentVolume         : null,
	shutdownEnabled       : false,
	selected              : false,
	loginEnabled          : false,
	raisedHand            : false,
	raisedHandInProgress  : false,
	joinInProgress        : false,
	loggedIn              : false,
	loggedInWithPin       : false,
	isRecorder            : false,
	isSpeaking            : false,
	isAutoMuted           : true
};

const me = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_SELECTED':
		{
			const { selected } = action.payload;

			return { ...state, selected };
		}

		case 'SET_ME':
		{
			const {
				peerId,
				loginEnabled
			} = action.payload;

			return {
				...state,
				id : peerId,
				loginEnabled
			};
		}

		case 'SET_BROWSER':
		{
			const { browser } = action.payload;

			return { ...state, browser };
		}

		case 'LOGGED_IN':
		{
			const { flag } = action.payload;

			return { ...state, loggedIn: flag };
		}

		case 'LOGGED_IN_WITH_PIN':
		{
			const { flag } = action.payload;

			return { ...state, loggedInWithPin: flag };
		}

		case 'IS_RECORDER':
		{
			const { flag } = action.payload;

			return { ...state, isRecorder: flag };
		}

		case 'ADD_ROLE':
		{
			const roles = [ ...state.roles, action.payload.roleId ];

			return { ...state, roles };
		}

		case 'REMOVE_ROLE':
		{
			const roles = state.roles.filter((roleId) =>
				roleId !== action.payload.roleId);

			return { ...state, roles };
		}

		case 'SET_PICTURE':
			return { ...state, picture: action.payload.picture };

		case 'SET_MEDIA_CAPABILITIES':
		{
			const {
				canSendMic,
				canSendWebcam,
				canShareScreen,
				canShareFiles
			} = action.payload;

			return {
				...state,
				canSendMic,
				canSendWebcam,
				canShareScreen,
				canShareFiles
			};
		}

		case 'SET_AUDIO_DEVICES':
		{
			const { devices } = action.payload;

			return { ...state, audioDevices: devices };
		}

		case 'SET_AUDIO_OUTPUT_DEVICES':
		{
			const { devices } = action.payload;

			return { ...state, audioOutputDevices: devices };
		}

		case 'SET_WEBCAM_DEVICES':
		{
			const { devices } = action.payload;

			return { ...state, webcamDevices: devices };
		}

		case 'SET_BMD_CHANGE_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, BMDChangeInProgress: flag };
		}

		case 'SET_BMD_ROUTER_STATE':
		{
			const { dest, id } = action.payload;

			const newRouterState = state.routerState;

			newRouterState[dest] = id;

			return { ...state, routerState: newRouterState };
		}

		case 'SET_BMD_ROUTER_CONFIG':
		{
			const { routerConfig } = action.payload;

			return { ...state, routerConfig };
		}

		case 'SET_CURRENT_VOLUME':
		{
			const { currentVolume } = action.payload;

			return { ...state, currentVolume };
		}

		case 'SET_SHUTDOWN_ENABLED':
		{
			const { shutdownEnabled } = action.payload;

			return { ...state, shutdownEnabled };
		}

		case 'SET_ADDRESS_BOOK':
		{
			const { addressBook } = action.payload;

			return { ...state, addressBook };
		}

		case 'SET_AUDIO_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, audioInProgress: flag };
		}

		case 'SET_WEBCAM_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, webcamInProgress: flag };
		}

		case 'SET_SCREEN_SHARE_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, screenShareInProgress: flag };
		}

		case 'SET_RAISED_HAND':
		{
			const { flag } = action.payload;

			return { ...state, raisedHand: flag };
		}

		case 'SET_RAISED_HAND_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, raisedHandInProgress: flag };
		}

		case 'SET_JOIN_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, joinInProgress: flag };
		}

		case 'SET_DISPLAY_NAME_IN_PROGRESS':
		{
			const { flag } = action.payload;

			return { ...state, displayNameInProgress: flag };
		}

		case 'SET_IS_SPEAKING':
		{
			const { flag } = action.payload;

			return { ...state, isSpeaking: flag };
		}

		case 'SET_AUTO_MUTED':
		{
			const { flag } = action.payload;

			return { ...state, isAutoMuted: flag };
		}

		default:
			return state;
	}
};

export default me;
